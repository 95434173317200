import { Link } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import SEO from '../components/seo';
import Navbar from '../components/Navbar';

export default function Privacidade(){
    return(
        <Layout>
            <SEO 
                title='Política de privacidade'
                description='Acompanhe e desenvolva as COMPETÊNCIAS e HABILIDADES que seus colaboradores necessitam para potencializar os resultados de sua empresa.'
            />
            <div className="w-full flex justify-center mb-10">
            
                <div className="flex flex-col w-4/5">

                    <Navbar/>

                    <div className="h-10"></div>

                    <div className="flex flex-col space-y-2">
                        <div className="text-3xl">
                        Política de Privacidade
                        </div>
                        <div className="flex flex-col text-xl font-light space-y-2">
                            Atualizado 01 de maio de 2022.
                        </div>
                    </div>

                    <div className="mt-10 flex flex-col space-y-4 ">

                    <div className="text-xl">
                        1. DEFINIÇÕES
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-semibold">SHELFIN:</span> SHELFIN TÉCNOLOGIA DA INFORMAÇÃO LTDA., sociedade empresária limitada, inscrita no CNPJ sob o nº. 45.721.306/0001-75, com sede à rua Nelson Ferreira da Luz, 81, Guatupê, São José dos Pinhais/PR, CEP 83065-456.
                    </div>  
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-semibold">PLATAFORMA:</span> Gestão de competências, feedbacks de líderes aos colaboradores, trilhas de carreiras e desenvolvimento de pessoas.
                    </div>  
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-semibold">USUÁRIOS</span>
                    </div>  
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-semibold">ADMINISTRADOR:</span> Pessoa Jurídica de Direito Privado ou Pessoa Física absolutamente capaz nos termos da legislação brasileira vigente que procederá à contratação da ferramenta. É de sua responsabilidade designar outros usuários para que façam uso da plataforma, atribuindo acessos específicos, bem como alimentar esta com dados dos usuários respondentes, mantendo todas as informações atualizadas e respondendo legalmente pelo cliente.
                    </div> 
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-semibold">USUÁRIO:</span> Pessoa designada pelo administrador para utilizar nossa ferramenta de maneira específica, podendo ser de cargo de liderança ou não. 
                    </div> 
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-semibold">GESTORES:</span> Fornece feedbacks, responde as avaliações, acompanha e interage no Plano de Desenvolvimento Individual, visualiza os resultados de seus colaboradores diretamente na plataforma. 
                    </div> 
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-semibold">COLABORADORES:</span> Toma conhecimento das competências esperadas por sua empresa e seus gestores, acompanha sua trilha de carreira, desenvolve a planos de desenvolvimento individual e aprende novas competências e Habilidades.
                    </div>
                    <div className="border-b border-gray-200 w-full my-4 lg:w-2/3"></div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-semibold">TRATAMENTO DE DADOS:</span> Nos termos do artigo 5, inciso X, da Lei 13.709 de 2018, toda operação realizada pela SHELFIN com dados pessoais dos usuários na plataforma, como as que se referem a coleta, produção, recepção, classNameificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
                    </div>
                    <div className="text-xl">
                        2. POLÍTICA DE PRIVACIDADE E DO TRATAMENTO DOS DADOS:
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        Durante a utilização da ferramenta, pelo USUÁRIO, a SHELFIN coletará e armazenará as informações fornecidas pelos USUÁRIOS, nos termos deste instrumento, conjuntamente com informações geradas automaticamente, tais como, as características do dispositivo de acesso, do navegador, registros de acesso à aplicação (IP, com data e hora), informações acessadas, telas acessadas, histórico de aplicações e outras, dos usuários, que serão armazenadas no banco de dados e também diversos cookies dos aplicativos navegadores.
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                    Os dados coletados dos usuários pela SHELFIN por meio da utilização de nossas ferramentas serão utilizados para a prestação de serviços, para fins de aprimoramento da navegação, bem como para fins de estudos estatísticos de utilização, melhoria de funcionalidades.
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                    O usuário declara compreender, aceitar e consentir que todos os dados coletados por meio da plataforma SHELFIN sejam tratados, nos termos do artigo 5, inciso X, da Lei 13.709 de 2018, pela SHELFIN ou por terceiros listados no seguinte link: https:/www.shelfin.com.br/privacidade-fornecedores/.
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                    Todos os dados fornecidos à SHELFIN, por meio da utilização da plataforma, serão considerados confidenciais pela SHELFIN  que se compromete a adotar os esforços com o intuito de preservar a segurança de seus sistemas na guarda de tais dados, atendendo aos padrões de segurança estabelecidas no Decreto nº 8.771/2016, tais como:
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">1. </span> Utilização de métodos padrões de mercado para criptografar os dados coletados, além de demais formas padrão de encriptação, para garantir sua inviolabilidade;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">2. </span> Emprego de softwares de alta tecnologia para proteção contra o acesso não autorizado aos sistemas, sendo estes considerados ambientes controlados e de segurança;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">3. </span> Disponibilização de acesso controlado a locais de armazenamento de dados pessoais apenas a pessoas previamente autorizadas e autenticadas, comprometidas ao sigilo de tais dados, inclusive mediante a assinatura de termo de confidencialidade;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">4. </span>Aplicação de mecanismos de autenticação de acesso aos registros capazes de individualizar o responsável pelo tratamento e acesso dos dados coletados em decorrência da utilização da plataforma;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">5.</span> Anonimização dos dados dos usuários ao serem compartilhados com terceiros não parceiros da SHELFIN para fins de contribuição científica e estudos de mercado.
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">6. </span> Manutenção do inventário indicando momento, duração, identidade do funcionário ou do responsável pelo acesso e o arquivo objeto, com base nos registros de conexão e de acesso a aplicações, conforme determinado no artigo 13 do Decreto nº 8.771/2016
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        Os dados dos usuários, coletados pela SHELFIN, por meio da utilização plataforma, poderão ser compartilhados com terceiros, nas hipóteses relacionadas abaixo:
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">1. </span> Quando necessário às atividades comerciais da SHELFIN, ou de prestadores de serviços, com o intuito de possibilitar o funcionamento de nossa plataforma. A relação dos dados pessoais dos usuários compartilhados, a finalidade de tal compartilhamento e a listagem das empresas referidas encontram-se disponíveis no seguinte link: https://www.shelfin.com.br/privacidade-fornecedores/.
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">2. </span> Para a proteção dos interesses da SHELFIN em caso de conflito, inclusive em demandas judiciais;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">3. </span> Em caso de transações e alterações societárias envolvendo a SHELFIN, hipótese em que a transferência dos dados será necessária para a continuidade dos serviços ofertados por meio da plataforma;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">4. </span> Mediante ordem judicial ou por requerimento de autoridades administrativas que detenham competência legal para sua requisição.
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        A SHELFIN garante ao usuário no que diz respeito ao processamento de dados pessoais, os seguintes direitos:
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">1. </span> A confirmação da existência de tratamento de seus dados pessoais;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">2. </span> O acesso aos seus dados coletados pela plataforma, através do seu próprio login ou mediante requisição ao contato@shelfin.com.br;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">3. </span> A correção de seus dados, caso estes estejam incompletos, inexatos ou desatualizados;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">4. </span> O bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a legislação brasileira aplicável;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">5. </span> A portabilidade dos dados pessoais, para si ou para terceiro, mediante requisição expressa feita pelo usuário à SHELFIN, através de contato@shelfin.com.br, resguardado o sigilo comercial e/ou confidencialidade das respostas às pesquisas, conferido à SHELFIN;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">6. </span> A eliminação dos dados pessoais tratados com o seu consentimento, desde que não haja determinação legal para mantê-los registrados junto à SHELFIN;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">7. </span> A obtenção de informações sobre entidades públicas ou privadas com as quais a SHELFIN compartilhou seus dados;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">8. </span> Informações sobre a possibilidade e consequência do não fornecimento do consentimento do usuário;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                    O usuário poderá enviar e-mail para contato@shelfin.com.br, apontando dúvidas e/ou requerimentos relacionados a seus dados pessoais.
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                    A SHELFIN poderá excluir os dados pessoais coletados dos usuários:
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">1. </span> Quando a finalidade para a qual foram coletados seja alcançada; ou, quando deixarem de ser necessários ou pertinentes para o alcance da finalidade, conforme finalidades descritas nos presentes Termos de Uso e Política de Privacidade;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">2. </span> Quando o usuário revogar seu consentimento, nos casos em que este se fizer necessário, requerendo a exclusão à SHELFIN através do contato@shelfin.com.br;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">3. </span> Quando expirar o prazo legal para retenção compulsória;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">4. </span> Caso seja determinado por autoridade competente.
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                    Os dados apresentados pelo ADMINISTRADOR são de sua inteira responsabilidade, sendo que a inserção de qualquer informação falsa, adulterada ou não autorizada por terceiros não geram qualquer responsabilidade à SHELFIN.
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                    A SHELFIN tratará seu conteúdo como informação confidencial e só o usará e divulgará de acordo com os Termos de Uso e Política de Privacidade (https://https:/www.shelfin.com.br/politica-de-privacidade/.
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                    No entanto, seu conteúdo não será considerado informação confidencial se:
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">1. </span> For ou se tornar público (desde que não seja por meio da violação destes Termos de Uso por parte da SHELFIN);
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">2. </span> Tiver chegado legalmente ao conhecimento da SHELFIN antes de ser recebido de você;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">3. </span> For recebido pela SHELFIN de terceiros, sem o conhecimento de violação de qualquer obrigação assumida com você;
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                        <span className="font-medium">4. </span> Tiver sido desenvolvido de forma independente pela SHELFIN, sem referência ao seu conteúdo.
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                    O presente instrumento constitui o entendimento integral entre o ADMINISTRADOR, USUÁRO e a SHELFIN e é regido pelas Leis Brasileiras, ficando eleito o foro da cidade de domicílio do usuário como único competente para dirimir questões decorrentes do presente instrumento, com renúncia expressa a qualquer outro foro, por mais privilegiado que seja.
                    </div>
                    <div className="text-lg font-light w-full lg:w-2/3">
                    Abaixo esclareceremos alguns pontos que julgamos importantes. Caso persista alguma dúvida acerca de quaisquer pontos discutidos ou não neste documento, por favor, não hesite em contatar-nos pelo endereço contato@shelfin.com.br.
                    </div>


                    </div>

                </div>

            </div>
        </Layout>
    );
}